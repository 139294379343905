<template>
    <section v-bind:class="['data-table', 'company-table']">
        <enso-table class="box is-paddingless raises-on-hover" id="companies">
            <template v-slot:avatar_id="{ row }">
                <div class="flex-centered">
                    <a class="figure-wrapper" @click="profile(row)">
                        <figure class="image is-24x24 avatar">
                            <img class="is-rounded"
                                v-bind:src="route('core.avatars.show', row.avatar_id)"
                                v-if="row.avatar_id">
                        </figure>
                    </a>
                </div>
            </template>
        </enso-table>
    </section>
</template>

<script>
import { EnsoTable } from '@enso-ui/tables/bulma';

export default {
    name: 'Index',

    components: { EnsoTable },

    inject: ['route'],

    methods: {
        profile(row) {
            this.$router.push({
                name: 'companies.show',
                params: { company: row.id },
            });
        },
    },
};
</script>
